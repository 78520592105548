import React from 'react'

import Layout from '../components/Layout'
import Header from '../components/Header'
import SEO from '../components/Seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header />
    <div className="container skinny taCenter">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist. ☹️</p>
    </div>
  </Layout>
)

export default NotFoundPage
